import React, { useState } from "react"

import MenuItem from "./MenuItem"
import MenuItemWithSubMenu from "./MenuItemWithSubMenu"
import { menuItems } from "../header/menuItems"

const MobileMenu = ({ className = "" }) => {
  const [activeItem, setActiveItem] = useState({
    subMenuIndex: null,
    subMenuIndexInner: null,
  })

  const handleSubMenu = (subMenuIndex = null) => {
    setActiveItem({
      ...activeItem,
      subMenuIndex:
        subMenuIndex !== activeItem.subMenuIndex ? subMenuIndex : null,
    })

    console.log("asda", activeItem)
  }

  const handleSubMenuInner = (subMenuIndexInner = null) => {
    setActiveItem({
      ...activeItem,
      subMenuIndexInner:
        subMenuIndexInner !== activeItem.subMenuIndexInner
          ? subMenuIndexInner
          : null,
    })
    console.log("asda23432", activeItem)
  }

  return (
    <>
      <ul
        className={`gap-0 px-3 font-semibold list-none text-neutral-700 flex flex-col text-body-md ${className}`}
      >
        {menuItems.map((item, index) => {
          if (item.menuItems) {
            return (
              <MenuItemWithSubMenu
                key={index}
                index={index}
                title={item.title}
                link={item.link}
                menuItems={item.menuItems}
                activeItem={activeItem}
                subMenuHandler={handleSubMenu}
                subMenuInnerHandler={handleSubMenuInner}
              />
            )
          } else {
            return (
              <MenuItem
                key={index}
                index={index}
                title={item.title}
                link={item.link}
                className="!text-neutral-700"
              />
            )
          }
        })}
      </ul>
    </>
  )
}

export default MobileMenu
