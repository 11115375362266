import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { useWindowScrollPosition } from "rooks"
import AOS from "aos"

import Header from "./header/Header"
import Footer from "./Footer"

const Layout = ({ children }) => {
  const { scrollY } = useWindowScrollPosition()

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    AOS.init({
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 100, // offset (in px) from the original trigger point
      delay: 200, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: "ease-out", // default easing for AOS animations
      once: true, // whether animation should happen only once - while scrolling down
    })

    setLoaded(true)
  }, [loaded])

  return (
    <>
      <GatsbySeo
        title={`Kloft`}
        description={`We serve globally to modernize static sites to take advantage of the blazing speeds of JAMStack while eliminating monthly maintenance and even probably, server costs.`}
      />
      <div
        className={
          process.env.NODE_ENV === "development" ? "debug-screens" : ""
        }
      >
        <div
          className={`flex flex-col h-screen ${
            loaded ? "opacity-100 visible" : "invisible opacity-0"
          }`}
        >
          <Header scrollY={scrollY} />
          <main className="flex-auto">{children}</main>
          <Footer />
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
