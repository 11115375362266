import React from "react"

const MenuList = ({ className = "", aside = false, children }) => {
  return (
    <ul
      className={`absolute flex flex-col py-3 text-left list-none w-[200px] dropdown-menu bg-white shadow-md border-t-[3px] rounded-bl-lg rounded-br-lg   ${
        aside
          ? "-top-2 right-0  border-t-secondary-500"
          : "top-full left-0 border-t-success-500"
      } ${className}`}
    >
      {children}
    </ul>
  )
}

export default MenuList
