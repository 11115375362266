export const menuItems = [
  {
    title: "Landings",
    link: "#",
    menuItems: [
      {
        title: "Landing 01",
        link: "/",
        menuItems: null,
      },
      {
        title: "Landing 02",
        link: "/home-2",
        menuItems: null,
      },
      {
        title: "Landing 03",
        link: "/home-3",
        menuItems: null,
      },
      {
        title: "Landing 04",
        link: "/home-4",
        menuItems: null,
      },
      {
        title: "Landing 05",
        link: "/home-5",
        menuItems: null,
      },
    ],
  },
  {
    title: "Pages",
    link: "#",
    menuItems: [
      {
        title: "About",
        link: "#",
        menuItems: [
          {
            title: "About 01",
            link: "/about-1",
            menuItems: null,
          },
          {
            title: "About 02",
            link: "/about-2",
            menuItems: null,
          },
        ],
      },
      {
        title: "Careers",
        link: "#",
        menuItems: [
          {
            title: "Careers Listing",
            link: "/careers",
            menuItems: null,
          },
          {
            title: "Career Details",
            link: "/career-details",
            menuItems: null,
          },
        ],
      },
      {
        title: "Pricing",
        link: "#",
        menuItems: [
          {
            title: "Pricing 01",
            link: "/pricing-1",
            menuItems: null,
          },
          {
            title: "Pricing 02",
            link: "/pricing-2",
            menuItems: null,
          },
        ],
      },
      {
        title: "Testimonials",
        link: "#",
        menuItems: [
          {
            title: "Testimonial 01",
            link: "/testimonial-1",
            menuItems: null,
          },
          {
            title: "Testimonial 02",
            link: "/testimonial-2",
            menuItems: null,
          },
        ],
      },
      {
        title: "FAQs",
        link: "#",
        menuItems: [
          {
            title: "FAQ 01",
            link: "/faq-1",
            menuItems: null,
          },
          {
            title: "FAQ 02",
            link: "/faq-2",
            menuItems: null,
          },
        ],
      },
      {
        title: "Account",
        link: "#",
        menuItems: [
          {
            title: "Sign In",
            link: "/sign-in",
            menuItems: null,
          },
          {
            title: "Sign Up",
            link: "/sign-up",
            menuItems: null,
          },
          {
            title: "Reset Password",
            link: "/reset-password",
            menuItems: null,
          },
        ],
      },
      {
        title: "Utility",
        link: "#",
        menuItems: [
          {
            title: "404 Page 01",
            link: "/404",
            menuItems: null,
          },
          {
            title: "404 Page 02",
            link: "/404-2",
            menuItems: null,
          },
          {
            title: "Success Page",
            link: "/success",
            menuItems: null,
          },
          {
            title: "Coming Soon",
            link: "/coming-soon",
            menuItems: null,
          },
        ],
      },
    ],
  },
  {
    title: "Blogs",
    link: "#",
    menuItems: [
      {
        title: "Blog Left Sidebar",
        link: "/blog-1",
        menuItems: null,
      },
      {
        title: "Blog Right Sidebar",
        link: "/blog-2",
        menuItems: null,
      },
      {
        title: "Blog No Sidebar",
        link: "/blog-no-sidebar",
        menuItems: null,
      },
      {
        title: "Blog Details",
        link: "/blog-details",
        menuItems: null,
      },
    ],
  },
  {
    title: "Contacts",
    link: "#",
    menuItems: [
      {
        title: "Contact 01",
        link: "/contact-1",
        menuItems: null,
      },
      {
        title: "Contact 02",
        link: "/contact-2",
        menuItems: null,
      },
    ],
  },
]
