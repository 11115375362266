import React from "react"
import Button from "./Button"

const ButtonOutline = ({ className, ...rest }) => {
  return (
    <>
      <Button
        className={`bg-transparent hover:!bg-primary-50 border-neutral-300 hover:text-primary-600 text-neutral-700 focus:ring-opacity-50 ${className}`}
        {...rest}
      />
    </>
  )
}
export default ButtonOutline
