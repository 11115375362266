import React, { useContext } from "react"
import { Link } from "gatsby"
import { List } from "react-bootstrap-icons"

import Container from "../Container"
import Button from "../Button"
import ButtonOutline from "../ButtonOutline"
import HoverMenu from "../hover-menu/HoverMenu"
import OffCanvas from "../OffCanvas"
import MobileMenu from "../mobile-menu/MobileMenu"
import Logo from "../../images/svg/logo.svg"

import GlobalContext from "../../contexts/GlobalContext"

export const headerConfigDefault = {
  className: "",
  onScrollClassName: "",
  menuPosition: "center", //left, right
}

const HeaderMain = ({ scrollY }) => {
  const { headerConfig, visibleOffCanvas, toggleOffCanvas } =
    useContext(GlobalContext)

  return (
    <>
      <div
        className={`fixed left-1/2 top-0 w-full z-[999] transition-all ease-in  -translate-x-1/2  bg-primary-25 select-none	 ${
          headerConfig.className
        } ${
          scrollY > 0
            ? `bg-opacity-95 backdrop-blur-sm ${headerConfig.onScrollClassName}`
            : "bg-opacity-100"
        }`}
      >
        <header className={`py-4 laptop:py-5 `}>
          <Container>
            <div className="flex items-center justify-between gap-8 ">
              <Link to="#" className="inline-block">
                <Logo />
              </Link>
              <HoverMenu
                className={
                  headerConfig.menuPosition === "left"
                    ? "mr-auto"
                    : headerConfig.menuPosition === "right"
                    ? "ml-auto"
                    : ""
                }
              />
              <div className="items-center hidden gap-3 laptop:flex">
                <Link to="/sign-in">
                  <ButtonOutline
                    size="lg"
                    className="!border-0 !ring-0 !shadow-none hover:!bg-transparent"
                  >
                    Log in
                  </ButtonOutline>
                </Link>
                <Link to="/sign-up">
                  <Button size="lg">Sign up</Button>
                </Link>
              </div>
              <div className="flex items-center laptop:hidden">
                <button
                  className="relative p-2 -right-2"
                  onClick={() => {
                    toggleOffCanvas()
                  }}
                >
                  <List className="text-display-sm !text-neutral-900" />
                </button>
              </div>
            </div>
          </Container>
        </header>
      </div>
      <OffCanvas show={visibleOffCanvas} onHideOffcanvas={toggleOffCanvas}>
        <MobileMenu />
      </OffCanvas>
    </>
  )
}

export default HeaderMain
