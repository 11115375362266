import React, { useContext } from "react"
import {
  Dribbble,
  Facebook,
  Github,
  Linkedin,
  Twitter,
} from "react-bootstrap-icons"
import { Link } from "gatsby"

import Section from "./Section"
import Button from "./Button"
import Container from "./Container"
import TextSmall from "./TextSmall"
import Input from "../components/forms/Input"
import Logo from "../images/svg/logo.svg"

import GlobalContext from "../contexts/GlobalContext"

const MenuLinkItem = ({ className = "", children, linkTo = "#" }) => {
  return (
    <Link
      to={linkTo}
      className={`text-body-md hover:text-primary-600 duration-300 text-neutral-600 ${className}`}
    >
      {children}
    </Link>
  )
}

export const footerConfigDefault = {
  className: "",
  hasTop: false,
  hasMiddleSocial: false,
  hasBottomSocial: false,
  hasBottomSubscription: false,
  bottomClassName: "",
  bottomWrapperClassName: "",
}

const Footer = () => {
  const { footerConfig } = useContext(GlobalContext)
  return (
    <>
      <footer>
        <Section className={`!pb-0 ${footerConfig.className}`}>
          <Container className="">
            {/* wrap */}
            <div className="">
              {/* top-part */}
              {footerConfig.hasTop && (
                <div className="flex flex-col gap-12 pb-12 laptop:gap-16 laptop:pb-16">
                  <div className="flex flex-col items-center justify-between gap-10 tablet:flex-row">
                    <div className="flex flex-col gap-2">
                      <h6 className="font-bold text-display-xs text-neutral-900">
                        Join our newsletter
                      </h6>
                      <p className="text-body-md text-neutral-500">
                        We’ll send you a nice offer once per week.
                      </p>
                    </div>
                    <form className="flex items-center gap-4">
                      <Input
                        label=""
                        inputClassName="py-[10px] w-full"
                        type="email"
                        placeholder="Enter your email"
                        name="email"
                      />

                      <Button className="" size={"lg"}>
                        Subscribe
                      </Button>
                    </form>
                  </div>

                  <div className="w-full h-[1px] bg-neutral-200"></div>
                </div>
              )}

              {/* middle-part */}
              <div className="flex items-center justify-center pb-14">
                <div className="grid grid-cols-1 laptop:grid-cols-12">
                  <div className="col-span-4 pb-16 laptop:pb-0 laptop:pr-16">
                    <div className="flex flex-col gap-6 laptop:gap-8">
                      <Link to="#">
                        <Logo />
                      </Link>
                      <p className="text-body-lg text-neutral-500">
                        Design amazing digital experiences that create more
                        happy in the world.
                      </p>
                      {footerConfig.hasMiddleSocial && (
                        <div className="flex gap-6 ">
                          <a href="https://twitter.com/">
                            <Twitter className="font-medium text-neutral-500 text-display-xs" />
                          </a>
                          <a href="https://www.linkedin.com/">
                            <Linkedin className="font-medium text-neutral-500 text-display-xs" />
                          </a>
                          <a href="https://www.facebook.com/">
                            <Facebook className="font-medium text-neutral-500 text-display-xs" />
                          </a>
                          <a href="https://github.com/">
                            <Github className="font-medium text-neutral-500 text-display-xs" />
                          </a>
                          <a href="https://dribbble.com/">
                            <Dribbble className="font-medium text-neutral-500 text-display-xs" />
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-span-8">
                    <div className="grid grid-cols-2 gap-14 laptop:gap-8 laptop:grid-cols-4">
                      {/* card */}
                      <div className="flex flex-col gap-5">
                        <p className="font-medium text-body-sm text-neutral-500">
                          Landing
                        </p>
                        <div className="flex flex-col gap-3">
                          <MenuLinkItem linkTo="/">Landing 1</MenuLinkItem>
                          <MenuLinkItem linkTo="/home-2">
                            Landing 2
                          </MenuLinkItem>
                          <MenuLinkItem linkTo="/home-3">
                            Landing 3
                          </MenuLinkItem>
                          <MenuLinkItem linkTo="/home-4">
                            Landing 4
                          </MenuLinkItem>
                          <MenuLinkItem linkTo="/home-5">
                            Landing 5
                          </MenuLinkItem>
                        </div>
                      </div>
                      <div className="flex flex-col gap-5">
                        <p className="font-medium text-body-sm text-neutral-500">
                          Pages
                        </p>
                        <div className="flex flex-col gap-3">
                          <MenuLinkItem linkTo="/about-1">About 1</MenuLinkItem>
                          <MenuLinkItem linkTo="/about-2">About 2</MenuLinkItem>
                          <MenuLinkItem linkTo="/pricing-1">
                            Pricing 1
                          </MenuLinkItem>
                          <MenuLinkItem linkTo="/pricing-2">
                            Pricing 2
                          </MenuLinkItem>
                          <MenuLinkItem linkTo="/sign-in">Log In</MenuLinkItem>
                        </div>
                      </div>
                      <div className="flex flex-col gap-5">
                        <p className="font-medium text-body-sm text-neutral-500">
                          Pages
                        </p>
                        <div className="flex flex-col gap-3">
                          <MenuLinkItem linkTo="/blog-1">Blog 1</MenuLinkItem>
                          <MenuLinkItem linkTo="/blog-2">Blog 2</MenuLinkItem>
                          <MenuLinkItem linkTo="/blog-details">
                            Blog post 1
                          </MenuLinkItem>
                          <MenuLinkItem linkTo="/sign-up">
                            Register
                          </MenuLinkItem>
                          <MenuLinkItem linkTo="/reset-password">
                            Reset password
                          </MenuLinkItem>
                        </div>
                      </div>
                      <div className="flex flex-col gap-5">
                        <p className="font-medium text-body-sm text-neutral-500">
                          Pages
                        </p>
                        <div className="flex flex-col gap-3">
                          <MenuLinkItem linkTo="/careers">Careers</MenuLinkItem>
                          <MenuLinkItem linkTo="/career-details">
                            Job post
                          </MenuLinkItem>
                          <MenuLinkItem linkTo="/contact-1">
                            Contact 1
                          </MenuLinkItem>
                          <MenuLinkItem linkTo="/contact-2">
                            Contact 1
                          </MenuLinkItem>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>

          {/* bottom-part */}
          <div
            className={`py-10 bg-primary-25 ${footerConfig.bottomClassName}`}
          >
            <Container>
              <div
                className={`flex flex-col items-center justify-between gap-10 tablet:flex-row ${footerConfig.bottomWrapperClassName}`}
              >
                <TextSmall className="">
                  © 2027 Kloft. All rights reserved.
                </TextSmall>
                {footerConfig.hasBottomSocial && (
                  <div className="flex gap-6 ">
                    <a href="https://twitter.com/">
                      <Twitter className="font-medium text-neutral-500 text-display-xs" />
                    </a>
                    <a href="https://www.linkedin.com/">
                      <Linkedin className="font-medium text-neutral-500 text-display-xs" />
                    </a>
                    <a href="https://www.facebook.com/">
                      <Facebook className="font-medium text-neutral-500 text-display-xs" />
                    </a>
                    <a href="https://github.com/">
                      <Github className="font-medium text-neutral-500 text-display-xs" />
                    </a>
                    <a href="https://dribbble.com/">
                      <Dribbble className="font-medium text-neutral-500 text-display-xs" />
                    </a>
                  </div>
                )}
                {footerConfig.hasBottomSubscription && (
                  <form className="flex items-center gap-4">
                    <Input
                      label=""
                      inputClassName="py-[10px] w-full"
                      type="email"
                      placeholder="Enter your email"
                      name="email"
                    />

                    <Button className="" size={"lg"}>
                      Subscribe
                    </Button>
                  </form>
                )}
              </div>
            </Container>
          </div>
        </Section>
      </footer>
    </>
  )
}

export default Footer
