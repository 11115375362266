import React from "react"

const MenuList = ({ className = "", children, isActive = false }) => {
  return (
    <>
      <ul className={`flex flex-col py-2 text-left list-none `}>{children}</ul>
    </>
  )
}

export default MenuList
