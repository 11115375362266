import React from "react"
import { ChevronDown } from "react-bootstrap-icons"
import { Link } from "gatsby"
import TinyCollapse from "react-tiny-collapse"

import MenuItem from "./MenuItem"
import MenuList from "./MenuList"

const MenuItemWithSubMenu = ({
  title = "SubMenu Title",
  link = "#",
  className = "",
  menuItems = null,
  activeItem,
  subMenuHandler = () => {},
  subMenuInnerHandler = () => {},
  index,
}) => {
  return (
    <>
      <li
        className={`relative hover:text-primary-600 text-body-md text-neutral-700 ${className}`}
      >
        <Link
          to={link}
          className="inline-flex items-center justify-between w-full gap-2 py-4 overflow-hidden "
          onClick={() => subMenuHandler(index)}
        >
          {title}
          <ChevronDown
            className={`duration-300 -rotate-90 text-body-sm ${
              activeItem.subMenuIndex === index && "rotate-0"
            }`}
          />
        </Link>

        <TinyCollapse isOpen={activeItem.subMenuIndex === index}>
          <MenuList>
            {menuItems.map((itemInner, indexInner) => {
              if (itemInner.menuItems) {
                return (
                  <li
                    className={`relative hover:text-success-600 text-body-md text-neutral-600 group ${className}`}
                    key={indexInner}
                  >
                    <Link
                      to={itemInner.link}
                      className="inline-flex items-center justify-between w-full gap-2 py-4 pl-4 overflow-hidden"
                      onClick={e => {
                        e.stopPropagation()
                        subMenuInnerHandler(indexInner)
                      }}
                    >
                      {itemInner.title}
                      <ChevronDown
                        className={`duration-300 -rotate-90 text-body-sm ${
                          activeItem.subMenuIndexInner === indexInner &&
                          "rotate-0"
                        }`}
                      />
                    </Link>
                    <TinyCollapse
                      isOpen={activeItem.subMenuIndexInner === indexInner}
                    >
                      <MenuList>
                        {itemInner.menuItems.map(
                          (innerMost, indexInnerMost) => (
                            <MenuItem
                              title={innerMost.title}
                              link={innerMost.link}
                              key={indexInnerMost}
                              className="hover:!text-secondary-600 !pl-8"
                            />
                          )
                        )}
                      </MenuList>
                    </TinyCollapse>
                  </li>
                )
              } else {
                return (
                  <MenuItem
                    title={itemInner.title}
                    link={itemInner.link}
                    key={indexInner}
                    className="hover:!text-success-600 !pl-4"
                  />
                )
              }
            })}
          </MenuList>
        </TinyCollapse>
      </li>
    </>
  )
}

export default MenuItemWithSubMenu
