import React from "react"

const Input = ({
  type = "text",
  name = "",
  id = "",
  placeholder = "John Doe",
  className = "",
  inputClassName = "",
  labelClassName = "",
  label = "Name",
  required = true,
  defaultValue = "",
  defaultChecked = false,
}) => {
  return (
    <>
      {type === "checkbox" || type === "radio" ? (
        <div className={`inline-flex items-center ${className}`}>
          <input
            type={type}
            id={id}
            className={`rounded shadow-sm border-neutral-300 text-primary-600 focus:border-primary-300 focus:ring focus:ring-offset-0   focus:ring-primary-200 focus:ring-opacity-50 ${inputClassName}`}
            name={name}
            required={required}
            defaultChecked={defaultChecked}
          />
          {label && (
            <label
              className={`ml-2 text-neutral-500 ${labelClassName}`}
              htmlFor={id}
            >
              {label}
            </label>
          )}
        </div>
      ) : (
        <label className={`block w-full  ${className}`} htmlFor={id}>
          {label && (
            <span
              className={`text-neutral-700 inline-block  mb-2 font-medium ${labelClassName}`}
            >
              {label}
            </span>
          )}
          <input
            type={type}
            id={id}
            className={`block w-full rounded-lg shadow-xs border-neutral-300 focus:border-primary-300 focus:ring duration-200 outline-none  focus:ring-neutral-300 focus:ring-opacity-50 ${inputClassName}`}
            name={name}
            placeholder={placeholder}
            required={required}
            defaultValue={defaultValue}
          />
        </label>
      )}
    </>
  )
}
export default Input
