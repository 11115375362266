import React from "react"
import { XLg } from "react-bootstrap-icons"

import Container from "./Container"
import Logo from "../images/svg/logo.svg"

const OffCanvas = ({ show, onHideOffcanvas, children }) => {
  return (
    <div className="select-none ">
      {/* Overlay */}
      <div
        aria-hidden
        className={`fixed z-[9999] inset-0 bg-black/50 backdrop-blur-sm w-full h-full duration-300 ease-in-out  ${
          show ? "visible opacity-100" : "opacity-0 invisible"
        }`}
        onClick={onHideOffcanvas}
        onKeyDown={onHideOffcanvas}
      />
      {/* Drawer */}
      <div
        className={`fixed z-[9999] w-72 h-full inset-y-0 left-0 bg-white  duration-300 ease-in-out ${
          show ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="py-4 bg-primary-25">
          <Container>
            <div className="flex items-center justify-between">
              <Logo onClick={onHideOffcanvas} />
              <button
                className="relative p-2 -right-2"
                onClick={onHideOffcanvas}
              >
                <XLg className="text-display-xs" />
              </button>
            </div>
          </Container>
        </div>

        <div className="h-[calc(100%_-_75px)] py-7 overflow-y-auto">
          <Container>{children}</Container>
        </div>
      </div>
    </div>
  )
}

export default OffCanvas
