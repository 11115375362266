import React from "react"

const TextSmall = ({ size = "", children, className = "" }) => {
  return (
    <>
      <p
        className={`font-normal text-body-sm tablet:text-body-md text-neutral-500  ${className}`}
      >
        {children}
      </p>
    </>
  )
}

export default TextSmall
