import React from "react"
import Tag from "./Tag"

const Button = ({
  type = "button",
  tagName = "button",
  children = "Button",
  className = "",
  size,
  iconClass = "text-body-2xl",
  icon,
  iconOnly = false,
  iconRight = true,
  onClick = () => {},
  ...props
}) => {
  let sizeClass

  if (size === "2xl") {
    sizeClass =
      "px-5 py-3 text-body-md laptop:px-7 laptop:py-4 laptop:text-body-lg"
  } else if (size === "xl") {
    sizeClass = "px-4 py-2 text-body-md tablet:px-5 tablet:py-3 "
  } else if (size === "lg") {
    sizeClass = "px-4 py-2 text-body-md"
  } else {
    // default md
    sizeClass = "px-3 py-2 text-body-sm"
  }

  return (
    <>
      <Tag
        tagName={tagName}
        className={`border justify-center  border-primary-400 text-center bg-primary-600 text-white flex gap-2 items-center rounded-lg hover:bg-primary-700 transition-all duration-300 ease-out hover:border-primary-500 font-medium hover:shadow-p-lg  shadow-p-xs whitespace-nowrap focus:border-primary-300 focus:ring focus:ring-offset-0 focus:ring-primary-200 select-none    ${sizeClass} ${className}`}
        onClick={onClick}
        type={type}
        {...props}
      >
        {!iconRight && (
          <span className={`flex items-center justify-center ${iconClass}`}>
            {icon}
          </span>
        )}
        {!iconOnly && children}
        {iconRight && (
          <span className={`flex items-center justify-center ${iconClass}`}>
            {icon}
          </span>
        )}
      </Tag>
    </>
  )
}
export default Button
