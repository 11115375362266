import React, { useContext } from "react"
import { Link, navigate } from "gatsby"

import GlobalContext from "../../contexts/GlobalContext"

const MenuItem = ({ title = "Menu Title", link = "#", className = "" }) => {
  const { toggleOffCanvas } = useContext(GlobalContext)

  const linkTo = link => {
    navigate(link)
    toggleOffCanvas()
  }
  return (
    <>
      <li
        className={`duration-300 ease-out hover:text-primary-600 text-body-md text-neutral-600 ${className}`}
      >
        <Link
          to={link}
          onClick={() => linkTo(link)}
          className="inline-flex w-full py-4"
        >
          {title}
        </Link>
      </li>
    </>
  )
}

export default MenuItem
